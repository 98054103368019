<template>
  <div v-if="mode">
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="588">
      <v-card class="modal">
        <v-card-title class="modal-title">{{ mode.title }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div class="pt-5">
            <p v-if="mode.message">{{ mode.message }}</p>

            <div class="mt-3">
              <h3>Quantos equipamentos vão alterar o status?</h3>

              <div class="d-flex justify-center mt-3">
                <number-field v-model="selectedAmount" :max="amount" />
              </div>
            </div>

            <div v-if="mode.partMessage" class="mt-3">
              <h3>{{ mode.partMessage }}</h3>
              <div class="d-flex justify-center mt-2">
                <v-radio-group v-model="partCheckbox" :column="false">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 black-3--text cancel-button"
            color="#ffffff"
            :block="$isMobile"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            class="ma-0 mt-1 white--text cancel-button"
            :block="$isMobile"
            :disabled="invalid"
            @click="handleConfirm"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import NumberField from "@/modules/core/views/components/NumberField";
  import { reconditioningSituations } from "@/modules/stock/reconditioning/enums/reconditioningSituations.enum";

  export default {
    name: "ReconditioningChangeSituationEquipmentDialog",

    props: {
      state: {
        type: Object,
        required: true
      },
      situationId: {
        type: Number,
        required: true
      },
      amount: {
        type: Number,
        required: true
      },
      fnConfirm: {
        type: Function,
        required: true
      }
    },

    components: {
      NumberField
    },

    data: () => ({
      selectedAmount: 0,
      partCheckbox: undefined,

      mode: undefined,
      modes: {
        [reconditioningSituations.IN_RECONDITION.id]: {
          title: `Alterar status para ${reconditioningSituations.IN_RECONDITION.description}`,
          message:
            "Ao alterar esse status você confirma que o equipamento foi testado e tem possibilidade de ser consertado.",
          partMessage: "É necessário algum componente do banco de peças?"
        },
        [reconditioningSituations.TO_DEMANUFACTURE.id]: {
          title: `Devolver equipamento para Desmanufatura`,
          message:
            "Ao alterar esse status você confirma que o equipamento foi testado e não tem possibilidade de ser consertado, então será desmanufaturado.",
          partMessage: "Algum componente será destinado ao banco de peças?"
        },
        [reconditioningSituations.TESTING.id]: {
          title: `Alterar status para ${reconditioningSituations.TESTING.description}`,
          message: undefined
        },
        [reconditioningSituations.RECONDITIONED.id]: {
          title: `Alterar status para ${reconditioningSituations.RECONDITIONED.description}`,
          message:
            "Ao alterar este status não é possível reverter ou fazer alteração referente a este equipamento."
        }
      }
    }),

    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },

    computed: {
      invalid() {
        return !this.selectedAmount || this.checkboxInvalid;
      },

      checkboxInvalid() {
        if (!this.mode.partMessage) {
          return false;
        }
        return this.partCheckbox === undefined;
      }
    },

    methods: {
      onDialogOpen() {
        this.mode = this.modes[this.situationId];
      },

      handleConfirm() {
        this.state.dialog = false;
        this.fnConfirm({
          situationId: this.situationId,
          useParts: !!this.partCheckbox,
          selectedAmount: this.selectedAmount
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-input--selection-controls.v-input {
    min-width: 250px;
  }
  ::v-deep .v-input--radio-group__input {
    justify-content: space-between !important;
  }
</style>
