<template>
  <div>
    <field
      :key="`field-${updateKey}`"
      class="situation-field my-3"
      @change="prepareToChangeSituation"
      :value="reconditioningItem.situationId"
      :items="reconditioningSituations"
      item-text="description"
      item-value="id"
      type="select"
      name="status"
      :isRequired="false"
      hide-details
    />

    <reconditioning-change-situation-part-dialog
      v-if="isPart"
      :key="`reconditioning-change-situation-part-${updateKey}`"
      :state="changeSituationDialogState"
      :situationId="changeToSituationId"
      :amount="reconditioningItem.amount"
      :fnConfirm="handleConfirmChangeSituation"
    />

    <reconditioning-change-situation-equipment-dialog
      v-if="isEquipment"
      :key="`reconditioning-change-situation-equipment-${updateKey}`"
      :state="changeSituationDialogState"
      :situationId="changeToSituationId"
      :amount="reconditioningItem.amount"
      :fnConfirm="handleConfirmChangeSituation"
    />
  </div>
</template>

<script>
  import Field from "@/modules/core/views/components/Field";
  import ReconditioningChangeSituationPartDialog from "@/modules/stock/reconditioning/view/components/ReconditioningChangeSituationPartDialog";
  import ReconditioningChangeSituationEquipmentDialog from "@/modules/stock/reconditioning/view/components/ReconditioningChangeSituationEquipmentDialog";

  import { batchItemTypes } from "@/modules/stock/batch/enums/batchItemTypes.enum";

  export default {
    name: "ReconditioningSituation",

    props: {
      reconditioningItem: {
        type: Object,
        required: true
      },
      fnChangeSituation: {
        type: Function,
        required: true
      }
    },

    components: {
      Field,
      ReconditioningChangeSituationPartDialog,
      ReconditioningChangeSituationEquipmentDialog
    },

    data: () => ({
      updateKey: 1,
      changeSituationDialogState: {
        dialog: false
      },

      changeToSituationId: 0
    }),

    watch: {
      "changeSituationDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.updateKey++;
        }
      }
    },

    computed: {
      reconditioningSituations() {
        return this.reconditioningItem.avaliableSituations;
      },

      isPart() {
        return this.reconditioningItem.type === batchItemTypes.PART.id;
      },

      isEquipment() {
        return this.reconditioningItem.type === batchItemTypes.EQUIPMENT.id;
      }
    },

    methods: {
      prepareToChangeSituation(situationId) {
        if (situationId === this.reconditioningItem.situationId) {
          return;
        }

        this.changeToSituationId = situationId;
        this.changeSituationDialogState.dialog = true;
      },

      handleConfirmChangeSituation(payload) {
        this.fnChangeSituation({
          ...payload,
          id: this.reconditioningItem.id
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .situation-field {
    max-width: 270px;
  }
</style>
