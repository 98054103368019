<template>
  <v-container>
    <title-header :title="$route.meta.title" />
    <v-card class="mt-5 pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3">
                <v-select
                  :items="situations"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('situationId', $event)"
                  color="secondary-color-1"
                  label="Situação"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  @input="setFilterDebounced('search', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar equipamento"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-if="items.length">
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="dataTableOptions"
              :server-items-length="tableInfo.totalItems"
              hide-default-footer
            >
              <template v-slot:[`item.situationId`]="{ item }">
                <reconditioning-situation
                  :reconditioningItem="item"
                  :fnChangeSituation="handleChangeSituation"
                />
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <div class="pt-10">
              <reconditioning-not-found :title="dataNotFound" />
            </div>
          </template>
        </div>

        <div v-if="items.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import ReconditioningNotFound from "@/modules/stock/reconditioning/view/components/ReconditioningNotFound";
  import ReconditioningSituation from "@/modules/stock/reconditioning/view/components/ReconditioningSituation";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  import { reconditioningSituations } from "@/modules/stock/reconditioning/enums/reconditioningSituations.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "ListReconditioning",

    components: {
      TitleHeader,
      ReconditioningNotFound,
      Pagination,
      ReconditioningSituation
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      crcs: [],
      situations: [],

      items: [],

      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batchNumber"
        },
        { text: "Data Registro", value: "createdAt" },
        { text: "Equipamento/Componente", value: "typeDescription" },
        { text: "Marca/Modelo", value: "brandAndModelDescription" },
        { text: "Quantidade", value: "amount" },
        { text: "Status", value: "situationId" }
      ]
    }),

    created() {
      this.initFilter("crcId", null);
      this.initFilter("search", "");
    },

    mounted() {
      this.fetchReconditioning();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
      this.initializeSituations();
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),

      showFilters() {
        if (this.items.length) {
          return true;
        }
        return !this.items.length && !this.filterPristine;
      },

      dataNotFound() {
        if (!this.filterPristine) {
          return ["Nenhum material para recondicionar encontrado"];
        }
        return [
          "Você ainda não possui",
          "nenhum material para recondicionar",
          "Aguarde!"
        ];
      }
    },

    methods: {
      ...mapActions("stock/reconditioning", [
        "getReconditioning",
        "changeSituation",
        "setChangeSituationData"
      ]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchReconditioning() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getReconditioning(payload);

          this.setTableInfo(meta);
          this.items = this.formatReconditioningData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatReconditioningData(reconditioningData) {
        return reconditioningData.map(reconditioningItem => {
          return {
            ...reconditioningItem,
            createdAt: this.parseDate(
              reconditioningItem.createdAt,
              "dd/MM/yyyy"
            )
          };
        });
      },

      updateData() {
        this.fetchReconditioning();
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            {
              description: "Todos",
              id: ""
            },
            ...data
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      initializeSituations() {
        this.situations = [
          {
            id: null,
            description: "Todas"
          },
          ...Object.values(reconditioningSituations).filter(
            situation =>
              situation.id !== reconditioningSituations.RECONDITIONED.id
          )
        ];
      },

      handleChangeSituation(payload) {
        if (payload.useParts) {
          this.setChangeSituationData({
            situationId: payload.situationId,
            batchItemId: payload.id,
            amount: payload.selectedAmount
          });
          return this.$router.push({
            name: "reconditioning-require-parts",
            params: { id: payload.id }
          });
        }

        this.callChangeSituation({
          id: payload.id,
          body: {
            situation: {
              id: payload.situationId
            },
            amount: payload.selectedAmount
          }
        });
      },

      async callChangeSituation(payload) {
        try {
          const response = await this.changeSituation(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro atualizado com sucesso!",
            type: "success"
          });

          this.updateData();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style></style>
