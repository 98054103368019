<template>
  <data-not-found
    :title="title"
    :imgSrc="require('@/assets/reconditioning.svg')"
  />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  export default {
    name: "ReconditioningNotFound",

    props: {
      title: {
        type: Array,
        required: true
      }
    },

    components: { DataNotFound }
  };
</script>

<style></style>
